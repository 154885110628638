.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidebar {
  height: 100%;
  width: 250px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #111;
  padding-top: 20px;
  color: white;
}

.sidebar a {
  padding: 15px;
  text-decoration: none;
  font-size: 18px;
  color: white;
  display: block;
  transition: 0.3s;
}

/* .sidebar a:hover {
  background-color: #555;
} */

.sidebar {
  height: 100%;
  width: 250px;
  /* position: fixed; */
  top: 0;
  left: 0;
  background-color: #111;
  padding-top: 20px;
  color: white;
}

.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  padding: 10px;
}

.sidebar a {
  text-decoration: none;
  color: white;
  font-size: 18px;
  height: 59px;
}

/* Dropdown styles */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  padding: 10px;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #333;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  display: block;
}

.dropdown-content a:hover {
  background-color: #555;
  border-radius: 20px;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.main .col-md-3 {
  width: 20%;
}

.active {
  background-color: #dd2f2f;
  border-radius: 20px;
  
  padding: 10px;
}
.search_box{
  position: absolute;
    z-index: 1;
    background: white;
    list-style: none;
    padding: 0px;
}
.search_box input {
  padding: 8px;
  margin-right: 8px;
}

.search_box ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
}

.search_box li {
  padding: 8px;
  cursor: pointer;
}

.search_box li:hover {
  background-color: #f2f2f2;
}
.loder{
  position: absolute;
  margin-top: 150px;
  margin-left: 500px;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}